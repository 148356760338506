<script>
import axios from "axios";
import SortBackForth from '../../../components/SortBackForth.vue';
import Formatter from '../../../components/libraries/Formatter';
import { mapState } from "vuex";
import Popup from '../../../components/Popup.vue';
import {formatMoney, formatNumber, getStatusName} from "../../../helpers/utils";

const formatter = new Formatter();
/**
 * Widget Component
 */
export default {
  components: { SortBackForth, Popup },
  data() {
    return {
      sortFields: [
        {
          code: 'profit',
          name: this.$t('views.analyticByObject.sortFields.profit'),
          direction: '',
          active: false,
        },
        {
          code: 'profit_partner',
          name: this.$t('views.analyticByObject.sortFields.profitPartner'),
          direction: '',
          active: false,
        },
        {
          code: 'avg_check',
          name: this.$t('popularWords.avgCheck'),
          direction: '',
          active: false,
        },
        {
          code: 'order_count',
          name: this.$t('views.analyticByObject.sortFields.orderCount'),
          direction: 'DESC',
          active: true,
        },
        {
          code: 'page_count',
          name: this.$t('views.analyticByObject.sortFields.pageCount'),
          direction: '',
          active: false,
        },
        {
          code: 'remaining_toner',
          name: this.$t('views.analyticByObject.sortFields.remainingToner'),
          direction: '',
          active: false,
        },
      ],
      onlyErrors: false,
      dateConvert: [],
      sortParams: {},
      stat: [],
      searchString: '',
      operationStatuses: {
        'Ready': this.$t('views.analyticByObject.state.ready'),
        'Sleep': this.$t('views.analyticByObject.state.sleep'),
        'LowPower': this.$t('views.analyticByObject.state.lowPower'),
        'NeedsAttention': this.$t('views.analyticByObject.state.needsAttention'),
      },
      trayStatuses: {
        'READY': this.$t('views.analyticByObject.state.ready'),
        'REMOVE': this.$t('views.analyticByObject.state.remove'),
        'EMPTY': this.$t('views.analyticByObject.state.empty'),
      },
      downloadBlock: false,
      excelPopup: {
        show: false,
        closeButton: this.$t('popularWords.close'),
      },
      excelLink: null,
      excelLoading: false,
      forceUpdateDayInfoFix: false // это костыльная переменная чтоб форсированно триггерить обновление таблицы с инфой по заказам за день
    };
  },
  props: {
    datePeriod: Array
  },
  watch: {
    datePeriod: function (val) {
      let result = [];
      if (val.length) {
        result[0] = formatter.jsObjectToPhp(val[0]);
        result[1] = formatter.jsObjectToPhp(val[1]);
      }
      this.dateConvert = result;
    },
    dateConvert: function (val) {
      if(Object.keys(this.sortParams).length > 0 && !this.downloadBlock){
        this.getStat(val);
      }
    },
    sortParams: function () {
      if(this.dateConvert.length > 0 && !this.downloadBlock){
        this.getStat(this.dateConvert);
      }
    }
  },
  computed: {
    ...mapState({
      userPermissions: state => state.authorization.userPerm,
    }),
    computedStat(){
      let result = [];
      let searchStr = this.searchString;

      for(let key in this.stat){
        // Показать только с ошибками
        if(this.onlyErrors){
          if(this.stat[key].state.state == 'offline'){
            result[result.length] = this.stat[key];
          }else if(
            this.stat[key].state.status == 'Sleep' ||
            this.stat[key].state.status == 'LowPower' ||
            this.stat[key].state.status == 'NeedsAttention'
          ){
            result[result.length] = this.stat[key];
          }else if(
            this.stat[key].state.detail.tray.first_tray == 'REMOVE' ||
            this.stat[key].state.detail.tray.first_tray == 'EMPTY' ||
            this.stat[key].state.detail.tray.second_tray == 'EMPTY' ||
            this.stat[key].state.detail.tray.second_tray == 'EMPTY'
          ){
            result[result.length] = this.stat[key];
          }else if(
            this.stat[key].state.detail.toner.cyan_percent < 5 ||
            this.stat[key].state.detail.toner.magenta_percent < 5 ||
            this.stat[key].state.detail.toner.yellow_percent < 5 ||
            this.stat[key].state.detail.toner.black_percent < 5
          ){
            result[result.length] = this.stat[key];
          }else if(this.stat[key].state && this.stat[key].state.detail && this.stat[key].state.detail.fault.length){
            result[result.length] = this.stat[key];
          }
          continue;
        }

        if(searchStr == ''){
          result[result.length] = this.stat[key];
        }else{
          let isSearched = false;
          let regexp = new RegExp(`${searchStr}`, 'i');
          let serialNumber = ''+this.stat[key].serial_number +'';
          if(regexp.test(serialNumber)){
            isSearched = true;
            result[result.length] = this.stat[key];
          }
          if(!isSearched){
            let ip = ''+this.stat[key].ip +'';
            if(regexp.test(ip)){
              isSearched = true;
              result[result.length] = this.stat[key];
            }
          }
          if(!isSearched){
            let code = ''+this.stat[key].code +'';
            if(regexp.test(code)){
              isSearched = true;
              result[result.length] = this.stat[key];
            }
          }
          if(!isSearched){
            let address = this.stat[key].printerLocation.address;
            if(regexp.test(address)){
              isSearched = true;
              result[result.length] = this.stat[key];
            }
          }
          if(!isSearched && this.stat[key].printerFranchiseRelation){
            let legalMain = ''+this.stat[key].printerFranchiseRelation.franchRel.legalMain.legal_person_name +'';
            if(regexp.test(legalMain)){
              isSearched = true;
              result[result.length] = this.stat[key];
            }
          }
          if(!isSearched && this.stat[key].printerFranchiseRelation){
            let legalSlave = ''+this.stat[key].printerFranchiseRelation.franchRel.legalSlave.legal_person_name +'';
            if(regexp.test(legalSlave)){
              isSearched = true;
              result[result.length] = this.stat[key];
            }
          }

          if(!isSearched){
            let name = ''+this.stat[key].name +'';
            if(regexp.test(name)){
              isSearched = true;
              result[result.length] = this.stat[key];
            }
          }

        }
      }
      return result;
    }
  },
  methods: {
    getStatusName,
    formatNumber,
    formatMoney,
    timeToShort(dt){
      return formatter.utcPhpDateTimeToTime(dt);
    },
    getApexOptions(dates){
      return {
          dataLabels: {
              enabled: false
          },
          stroke: {
              curve: 'smooth',
              width: 3,
          },
          colors: ['#556ee6', '#34c38f'],
          xaxis: {
              type: 'date',
              categories: dates,
          },
          grid: {
              borderColor: '#f1f1f1',
          },
          tooltip: {
              x: {
                  format: 'dd/MM/yy HH:mm'
              },
          }
      };
    },
    setSort(val) {
      this.sortParams = val;
    },
    filterStatData(data) {
      for (let key in data) {
        data[key].show_order_list = false;

        if (!data[key].orders) {
          continue;
        }

        if (Object.keys(data[key].orders).length > 0) {
          for (let keyDay in data[key].orders) {
            data[key].orders[keyDay].show_orders = false;
          }
        }
      }
      return data;
    },
    getStat(dateArr) {
      this.downloadBlock = true;
      this.$store.dispatch("startPreloader");
      let formData = new FormData();
      formData.append("date_from", dateArr[0]);
      formData.append("date_to", dateArr[1]);
      formData.append("sort_params", JSON.stringify(this.sortParams));
      axios
        .post(`/v1/dashboard/objects-analytic`, formData)
        .then(resp => {
          this.downloadBlock = false;
          this.stat = this.filterStatData(resp.data);
          this.$store.dispatch("stopPreloader");
        })
        .catch(err => {
          this.downloadBlock = false;
          this.$store.dispatch("addNotification", {
            text: err.response.data.message,
            time: 6,
            color: "danger"
          });
          this.$store.dispatch("stopPreloader");
        });
    },
    updatePrinterState(sn) {
      let formData = new FormData();
      formData.append("printer_sn", sn);
      axios
        .post(`/v1/dashboard/update-printer-state`, formData)
        .then(resp => {
          for (let key in this.stat) {
            if (this.stat[key].serial_number == resp.data.serial_number) {
              this.stat[key].state = resp.data.state;
            }
          }
        })
        .catch(err => {
          this.$store.dispatch("addNotification", {
            text: err.response.data.message,
            time: 6,
            color: "danger"
          });
        });
    },
    downloadExcel(){
      let formData = new FormData();

      for(let key in this.computedStat){
        formData.append('printer_ids[]', this.computedStat[key].id);
      }
      formData.append("date_from", this.dateConvert[0]);
      formData.append("date_to", this.dateConvert[1]);
      this.excelLoading = true;
      axios
        .post(`/v1/dashboard/object-export-excel`, formData)
        .then(resp => {
          this.excelLink = resp.data.download_link;
          this.showExcelPopup();
        })
        .catch(err => {
          this.$store.dispatch("addNotification", {
            text: err.response.data.message,
            time: 6,
            color: "danger"
          });
        })
        .finally(() => {
          this.excelLoading = true;
        });
    },
    showExcelPopup(){
      this.excelPopup.show = true;
    },
    closeExcelPopup(){
      this.excelPopup.show = false;
    },
    toggleShowDetailedStatistic(item) {
      if (!item.show_order_list) {
        item.show_order_list = true;

        if (!item.chart || !item.orders) {
          // Подгружаем детальную статистику по принтеру
          const formData = new FormData();
          formData.append('printer_sn', item.serial_number);
          formData.append("date_from", this.dateConvert[0]);
          formData.append("date_to", this.dateConvert[1]);
          axios.post('/v1/dashboard/object-statistic', formData)
              .then(response => {
                item.chart = response.data.chart;
                item.orders = response.data.orders;

                // FIXME: Это костыль, чтобы форсированно обновить список принтеров
                item.show_order_list = false;
                this.$nextTick(() => { item.show_order_list = true; });
              })
              .catch(err => {
                this.$store.dispatch("addNotification", {
                  text: err.response.data.message,
                  time: 6,
                  color: "danger"
                });
              });
        }
      } else {
        item.show_order_list = false;
      }
    },
    toggleShowDayStatistic(day) {
      if (!day.show_orders) {
        day.show_orders = true;
      } else {
        day.show_orders = false;
      }
      this.forceUpdateDayInfoFix = false;
      this.$nextTick(() => { this.forceUpdateDayInfoFix = true; });
    }
  },
  created() {
    if (this.datePeriod.length) {
      let result = [];
      result[0] = formatter.jsObjectToPhp(this.datePeriod[0]);
      result[1] = formatter.jsObjectToPhp(this.datePeriod[1]);
      this.dateConvert = result;
    }
  }
};
</script>

<template>
  <div class="analytic-by-objects--container">
    <div class="row">
      <div class="col-4 position-relative">
        <div>
          <i class="pt-1 pl-1 ri-search-line position-absolute z-1"></i>
          <input v-model="searchString" type="text" class="form-control pl-4 rounded-pill border-0 form-control-sm"
          :placeholder="$t('views.analyticByObject.searchField')">
        </div>
        <div class="mt-3">
          <button @click="downloadExcel" class="btn btn-sm btn-success" :disabled="excelLoading">
            <span v-if="excelLoading" class="spinner-border spinner-border-sm" role="status"/>
            Выгрузить в Excel
          </button>
        </div>
      </div>
      <div class="col-6">
        <sort-back-forth :sortFields="sortFields" @selectSort="setSort"></sort-back-forth>
      </div>
      <div class="col-2">
        <b-form-checkbox v-model="onlyErrors" switch class="mb-1">
          <label>{{$t('views.analyticByObject.onlyErrors')}}</label>
        </b-form-checkbox>
      </div>
    </div>
    <div v-if="stat.length > 0" class="row pl-2 pr-2">
      <div class="col-12 pl-0">
        <div v-for="item in computedStat" :key="item.id" class="card mt-5">
          <div class="card-body">
            <div right toggle-class="arrow-none card-drop" class="float-right" variant="white">
              <!-- <a class="text-primary mr-2">
                  <i class="ri-bar-chart-fill font-size-20"></i>
                </a> -->
              <a
                :href="'https://' + item.ip + ':' + item.port"
                target="_blank"
                class="text-primary mr-2"
                v-if="userPermissions.includes('show_printer_link')"
              >
                <i class="ri-link-m font-size-20"></i>
              </a>
              <a @click="updatePrinterState(item.serial_number)" class="text-primary cursor-pointer">
                <i class="ri-refresh-line font-size-20"></i>
              </a>
            </div>

            <h4 class="card-title mb-4">
              {{ item.printerLocation.address }} |
              <span v-if="item.printerFranchiseRelation">
                {{ item.printerFranchiseRelation.franchRel.legalMain.legal_person_name }} ->
                {{ item.printerFranchiseRelation.franchRel.legalSlave.legal_person_name }}
              </span> |
              QR: {{ item.code }}
            </h4>

            <div class="analytic-by-objects--item">
              <div class="row mt-3">
                <div class="col-lg-12">
                  <table class="table table-hover" style="cursor: pointer;">
                    <thead>
                      <tr>
                        <th>{{$t('popularWords.sn')}}</th>
                        <th>{{$t('popularWords.online')}}</th>
                        <th>{{$t('popularWords.status')}}</th>
                        <th>{{$t('views.analyticByObject.printerStateTable.tray1')}}</th>
                        <th>{{$t('views.analyticByObject.printerStateTable.tray2')}}</th>
                        <th>Cyan</th>
                        <th>Magenta</th>
                        <th>Yellow</th>
                        <th>Key</th>
                        <th>{{$t('views.analyticByObject.printerStateTable.notification')}}</th>
                        <th>{{$t('views.analyticByObject.printerStateTable.updated')}}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{{ item.serial_number }}</td>
                        <td>
                          <span v-if="item.state">
                            <span v-if="item.state.state == 'offline'" class="badge ml-1 badge-danger">{{$t('popularWords.offline')}}</span>
                            <span v-if="item.state.state == 'online'" class="badge ml-1 badge-success">{{$t('popularWords.online')}}</span>
                          </span>
                        </td>
                        <td>
                          <span v-if="item.state">
                            {{ operationStatuses[item.state.status] }}
                          </span>
                        </td>
                        <td>
                          <span v-if="item.state && item.state.detail">
                            {{ trayStatuses[item.state.detail.tray.first_tray] }}
                          </span>
                        </td>
                        <td>
                          <span v-if="item.state && item.state.detail">
                            {{ trayStatuses[item.state.detail.tray.second_tray] }}
                          </span>
                        </td>
                        <td>
                          <span v-if="item.state && item.state.detail">
                            {{ item.state.detail.toner.cyan_percent }}%/{{ item.state.detail.toner.cyan_pages }}с
                          </span>
                        </td>
                        <td>
                          <span v-if="item.state && item.state.detail">
                            {{ item.state.detail.toner.magenta_percent }}%/{{ item.state.detail.toner.magenta_pages }}с
                          </span>
                        </td>
                        <td>
                          <span v-if="item.state && item.state.detail">
                            {{ item.state.detail.toner.yellow_percent }}%/{{ item.state.detail.toner.yellow_pages }}с
                          </span>
                        </td>
                        <td>
                          <span v-if="item.state && item.state.detail">
                            {{ item.state.detail.toner.black_percent }}%/{{ item.state.detail.toner.black_pages }}с
                          </span>
                        </td>
                        <td>
                          <span v-if="item.state && item.state.detail && item.state.detail.fault.length">
                            <div v-for="fault in item.state.detail.fault" :key="fault.primary_chain_link">
                              {{fault.type}}: {{fault.primary_chain_link}}
                            </div>
                          </span>
                          <span v-else>
                            {{$t('popularWords.empty')}}
                          </span>
                        </td>
                        <td>
                          <span v-if="item.state">
                            {{ item.state.updated_at }}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="row card analytic-by-objects--stat-card">
                <div class="col-12">
                  <div class="row cursor-pointer no-select" @click="toggleShowDetailedStatistic(item)">
                    <div class="col-9">
                      <div class="card-body">
                        <div class="text-center">
                          <div class="row">
                            <div class="col">
                              <p class="text-muted font-size-14 mb-2 mt-1">{{$t('views.analyticByObject.printerStatistics.totalOrders')}}</p>
                              <div class="font-size-16 font-weight-bold font-black text-nowrap">{{ formatNumber(item.statistic.order_count.period)
                              }}
                                {{$t('popularWords.pcs')}}</div>
                              <div class="text-truncate">
                                <span class="badge badge-soft-success font-size-11" :class="{
                                  'badge-soft-success': item.statistic.order_count.percent_diff.direction == 'up',
                                  'badge-soft-danger': item.statistic.order_count.percent_diff.direction == 'down',
                                  'badge-soft-info': item.statistic.order_count.percent_diff.direction == 'equally',
                                }">
                                  <i v-if="item.statistic.order_count.percent_diff.direction == 'up'"
                                    class="mdi mdi-menu-up"></i>
                                  <i v-if="item.statistic.order_count.percent_diff.direction == 'down'"
                                    class="mdi mdi-menu-down"></i>
                                  {{ item.statistic.order_count.percent_diff.value }}%
                                </span>
                              </div>
                            </div>
                            <div class="col">
                              <p class="text-muted font-size-14 mb-2 mt-1">{{$t('views.analyticByObject.printerStatistics.profit')}}</p>
                              <div class="font-size-16 font-weight-bold font-black text-nowrap">{{ formatMoney(item.statistic.income.period) }}
                                &#8381;</div>
                              <div class="text-truncate">
                                <span class="badge badge-soft-success font-size-11" :class="{
                                  'badge-soft-success': item.statistic.income.percent_diff.direction == 'up',
                                  'badge-soft-danger': item.statistic.income.percent_diff.direction == 'down',
                                  'badge-soft-info': item.statistic.income.percent_diff.direction == 'equally',
                                }">
                                  <i v-if="item.statistic.income.percent_diff.direction == 'up'"
                                    class="mdi mdi-menu-up"></i>
                                  <i v-if="item.statistic.income.percent_diff.direction == 'down'"
                                    class="mdi mdi-menu-down"></i>
                                  {{ item.statistic.income.percent_diff.value }}%
                                </span>
                              </div>
                            </div>
                            <div v-if="+item.statistic.income_partner.show === 1" class="col">
                              <p class="text-muted font-size-14 mb-2 mt-1">{{$t('views.analyticByObject.printerStatistics.partner')}}</p>
                              <div class="font-size-16 font-weight-bold font-black text-nowrap">
                                {{ formatMoney(item.statistic.income_partner.period) }} &#8381;</div>
                              <div class="text-truncate">
                                <span class="badge badge-soft-success font-size-11" :class="{
                                  'badge-soft-success': item.statistic.income_partner.percent_diff.direction == 'up',
                                  'badge-soft-danger': item.statistic.income_partner.percent_diff.direction == 'down',
                                  'badge-soft-info': item.statistic.income_partner.percent_diff.direction == 'equally',
                                }">
                                  <i v-if="item.statistic.income_partner.percent_diff.direction == 'up'"
                                    class="mdi mdi-menu-up"></i>
                                  <i v-if="item.statistic.income_partner.percent_diff.direction == 'down'"
                                    class="mdi mdi-menu-down"></i>
                                  {{ item.statistic.income_partner.percent_diff.value }}%
                                </span>
                              </div>
                            </div>
                            <div v-if="+item.statistic.income_my.show === 1" class="col">
                              <p class="text-muted font-size-14 mb-2 mt-1">{{$t('views.analyticByObject.printerStatistics.myProfit')}}</p>
                              <div class="font-size-16 font-weight-bold font-black text-nowrap">
                                {{ formatMoney(item.statistic.income_my.period) }} &#8381;</div>
                              <div class="text-truncate">
                                <span class="badge badge-soft-success font-size-11" :class="{
                                  'badge-soft-success': item.statistic.income_my.percent_diff.direction == 'up',
                                  'badge-soft-danger': item.statistic.income_my.percent_diff.direction == 'down',
                                  'badge-soft-info': item.statistic.income_my.percent_diff.direction == 'equally',
                                }">
                                  <i v-if="item.statistic.income_my.percent_diff.direction == 'up'"
                                    class="mdi mdi-menu-up"></i>
                                  <i v-if="item.statistic.income_my.percent_diff.direction == 'down'"
                                    class="mdi mdi-menu-down"></i>
                                  {{ item.statistic.income_my.percent_diff.value }}%
                                </span>
                              </div>
                            </div>
                            <div class="col">
                              <p class="text-muted font-size-14 mb-2 mt-1">{{$t('popularWords.avgCheck')}}</p>
                              <div class="font-size-16 font-weight-bold font-black text-nowrap">{{ formatMoney(item.statistic.check.period) }}
                                &#8381;</div>
                              <div class="text-truncate">
                                <span class="badge badge-soft-success font-size-11" :class="{
                                  'badge-soft-success': item.statistic.check.percent_diff.direction == 'up',
                                  'badge-soft-danger': item.statistic.check.percent_diff.direction == 'down',
                                  'badge-soft-info': item.statistic.check.percent_diff.direction == 'equally',
                                }">
                                  <i v-if="item.statistic.check.percent_diff.direction == 'up'"
                                    class="mdi mdi-menu-up"></i>
                                  <i v-if="item.statistic.check.percent_diff.direction == 'down'"
                                    class="mdi mdi-menu-down"></i>
                                  {{ item.statistic.check.percent_diff.value }}%
                                </span>
                              </div>
                            </div>
                            <div class="col">
                              <p class="text-muted font-size-14 mb-2 mt-1">{{$t('popularWords.bw')}}</p>
                              <div class="font-size-16 font-weight-bold font-black text-nowrap">{{ formatNumber(item.statistic.gray_orders.period)
                              }}
                                {{$t('popularWords.pcs')}}</div>
                              <div class="text-truncate">
                                <span class="badge badge-soft-success font-size-11" :class="{
                                  'badge-soft-success': item.statistic.gray_orders.percent_diff.direction == 'up',
                                  'badge-soft-danger': item.statistic.gray_orders.percent_diff.direction == 'down',
                                  'badge-soft-info': item.statistic.gray_orders.percent_diff.direction == 'equally',
                                }">
                                  <i v-if="item.statistic.gray_orders.percent_diff.direction == 'up'"
                                    class="mdi mdi-menu-up"></i>
                                  <i v-if="item.statistic.gray_orders.percent_diff.direction == 'down'"
                                    class="mdi mdi-menu-down"></i>
                                  {{ item.statistic.gray_orders.percent_diff.value }}%
                                </span>
                              </div>
                            </div>
                            <div class="col">
                              <p class="text-muted font-size-14 mb-2 mt-1">{{$t('popularWords.color')}}</p>
                              <div class="font-size-16 font-weight-bold font-black text-nowrap">{{ formatNumber(item.statistic.color_orders.period)
                              }}
                                {{$t('popularWords.pcs')}}</div>
                              <div class="text-truncate">
                                <span class="badge badge-soft-success font-size-11" :class="{
                                  'badge-soft-success': item.statistic.color_orders.percent_diff.direction == 'up',
                                  'badge-soft-danger': item.statistic.color_orders.percent_diff.direction == 'down',
                                  'badge-soft-info': item.statistic.color_orders.percent_diff.direction == 'equally',
                                }">
                                  <i v-if="item.statistic.color_orders.percent_diff.direction == 'up'"
                                    class="mdi mdi-menu-up"></i>
                                  <i v-if="item.statistic.color_orders.percent_diff.direction == 'down'"
                                    class="mdi mdi-menu-down"></i>
                                  {{ item.statistic.color_orders.percent_diff.value }}%
                                </span>
                              </div>
                            </div>
                            <div class="col">
                              <p class="text-muted font-size-14 mb-2 mt-1">{{$t('views.analyticByObject.sheets')}}</p>
                              <div class="font-size-16 font-weight-bold font-black text-nowrap">{{ formatNumber(item.statistic.sheet_of_paper.period)
                              }}
                                {{$t('popularWords.pcs')}}</div>
                              <div class="text-truncate">
                                <span class="badge badge-soft-success font-size-11" :class="{
                                  'badge-soft-success': item.statistic.sheet_of_paper.percent_diff.direction == 'up',
                                  'badge-soft-danger': item.statistic.sheet_of_paper.percent_diff.direction == 'down',
                                  'badge-soft-info': item.statistic.sheet_of_paper.percent_diff.direction == 'equally',
                                }">
                                  <i v-if="item.statistic.sheet_of_paper.percent_diff.direction == 'up'"
                                    class="mdi mdi-menu-up"></i>
                                  <i v-if="item.statistic.sheet_of_paper.percent_diff.direction == 'down'"
                                    class="mdi mdi-menu-down"></i>
                                  {{ item.statistic.sheet_of_paper.percent_diff.value }}%
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div v-if="item.show_order_list === true" class="row">
                    <div class="col-12 mt-0">
                      <div v-if="!item.chart" class="d-flex justify-content-center my-4">
                        <b-spinner variant="secondary" role="status"/>
                      </div>
                      <apexchart v-else
                        class="apex-charts"
                        height="350"
                        type="area"
                        dir="ltr"
                        :series="item.chart.series"
                        :options="getApexOptions(item.chart.dates)"
                      ></apexchart>
                    </div>
                  </div>

                </div>
              </div>

              <div class="row mt-4 position-relative">
                <div class="col-lg-12">
                  <table v-if="item.orders && item.show_order_list === true" class="table">
                    <thead>
                    <tr>
                      <th style="width: 75px; text-align: right;"></th>
                      <th style="width: 75px; text-align: right;"></th>
                      <th style="width: 75px; text-align: right;"></th>
                      <th style="width: 75px; text-align: right;">{{$t('popularWords.bw')}} {{$t('popularWords.pcs')}}</th>
                      <th style="width: 75px; text-align: right;">{{$t('popularWords.color')}} {{$t('popularWords.pcs')}}</th>
                      <th style="width: 75px; text-align: right;">{{$t('views.analyticByObject.printerDayStatistics.page')}} {{$t('popularWords.pcs')}}</th>
                      <th style="width: 75px; text-align: right;">{{$t('views.analyticByObject.printerDayStatistics.profit')}} {{$t('popularWords.rub')}}</th>
                      <th style="width: 75px; text-align: right;">
                        <span v-if="item.partner_or_my_income == 'partner'">
                          {{$t('views.analyticByObject.printerDayStatistics.partner')}} {{$t('popularWords.rub')}}
                        </span>
                        <span v-if="item.partner_or_my_income == 'my'">
                          {{$t('views.analyticByObject.printerDayStatistics.myProfit')}} {{$t('popularWords.rub')}}
                        </span>
                      </th>
                      <th style="width: 75px">{{$t('popularWords.avgCheck')}} {{$t('popularWords.rub')}}</th>
                      <th style="width: 75px">{{$t('popularWords.orders')}} {{$t('popularWords.pcs')}}</th>
                    </tr>
                    </thead>
                    <template
                        v-if="item.orders && Object.keys(item.orders).length > 0 && item.show_order_list === true"
                    >
                      <tbody
                          v-for="(day, index) in item.orders" :key="index"
                      >
                      <tr class="day-statistic-row" :class="{ 'active': day.show_orders === true }" @click="toggleShowDayStatistic(day)">
                        <td style="width: 75px; white-space: nowrap;">{{ index }}</td>
                        <td style="width: 75px"></td>
                        <td style="width: 75px"></td>
                        <td style="width: 75px; text-align: right;">{{ formatNumber(day.gray_count) }}</td>
                        <td style="width: 75px; text-align: right;">{{ formatNumber(day.color_count) }}</td>
                        <td style="width: 75px; text-align: right;">{{ formatNumber(day.page_count) }}</td>
                        <td style="width: 75px; text-align: right;">{{ formatMoney(day.income) }} &#8381;</td>
                        <td style="width: 75px; text-align: right;">
                          <span v-if="item.partner_or_my_income == 'partner'">{{ formatMoney(day.income_partner) }} &#8381;</span>
                          <span v-if="item.partner_or_my_income == 'my'">{{formatMoney(day.income_my)}} &#8381;</span>
                        </td>
                        <td style="width: 75px; text-align: right;">{{ formatMoney(day.avg_check) }} &#8381;</td>
                        <td style="width: 75px; text-align: right;">{{ formatNumber(day.order_count) }}</td>
                      </tr>
                      <tr v-if="forceUpdateDayInfoFix && day.list.length > 0 && day.show_orders === true">
                        <td style="padding: 0;" colspan="10">
                          <table style="width: 100%;" class="table table-active">
                            <!-- Развернутый день -->
                            <thead>
                            <tr>
                              <th></th>
                              <th>{{$t('views.analyticByObject.printerDayStatistics.userId')}}</th>
                              <th v-if="userPermissions.includes('show_client_number_dashboard')">
                                {{$t('views.analyticByObject.printerDayStatistics.phone')}}
                              </th>
                              <th>{{$t('views.analyticByObject.printerDayStatistics.orderId')}}</th>
                              <th>{{$t('popularWords.status')}}</th>
                              <th>{{$t('views.analyticByObject.printerDayStatistics.price')}}</th>
                              <th>{{$t('popularWords.color')}}</th>
                              <th>{{$t('views.analyticByObject.printerDayStatistics.duplex')}}</th>
                              <th>{{$t('views.analyticByObject.printerDayStatistics.time')}}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="ord in day.list" :key="ord.id">
                              <td></td>
                              <td style="vertical-align: middle;">{{ ord.user_id }}</td>
                              <td style="vertical-align: middle;" v-if="userPermissions.includes('show_client_number_dashboard')">
                                <span v-if="ord.userProfile">{{ ord.userProfile.phone_number }}</span>
                                <span v-if="!ord.userProfile">-</span>
                              </td>
                              <td style="vertical-align: middle;">{{ ord.id }}</td>
                              <td style="vertical-align: middle;">{{ getStatusName(ord.status_key) }}</td>
                              <td style="vertical-align: middle;">{{ formatMoney(ord.custom_price) }} &#8381;</td>
                              <td style="vertical-align: middle;">
                                <i v-if="ord.color == 1" class="ri-checkbox-circle-fill text-success font-size-18"></i>
                                <i v-if="ord.color == 0" class="ri-close-circle-fill text-danger font-size-18"></i>
                              </td>
                              <td style="vertical-align: middle;">
                                <i v-if="ord.duplex == 1" class="ri-checkbox-circle-fill text-success font-size-18"></i>
                                <i v-if="ord.duplex == 0" class="ri-close-circle-fill text-danger font-size-18"></i>
                              </td>
                              <td style="vertical-align: middle;">{{ timeToShort(ord.created_at) }}</td>
                            </tr>
                            </tbody>
                            <!-- Развернутый день end -->
                          </table>
                        </td>
                      </tr>
                      </tbody>
                    </template>
                    <template v-else>
                      <tbody><tr><td colspan="10" style="text-align: center;"><span style="opacity: 0.75;">Нет заказов за выбранный период</span></td></tr></tbody>
                    </template>
                  </table>
                </div>
                <div v-if="item.orders && Object.keys(item.orders).length > 0 && item.show_order_list === true"
                  class="position-absolute wrapper w-100 text-center">
                  <button @click="item.show_order_list = !item.show_order_list" type="button"
                    class="btn btn-secondary button">{{$t('popularWords.rollUp')}}</button>
                </div>
              </div>

            </div>

          </div>
        </div>
      </div>
    </div>
    <div v-if="stat.length === 0" class="row mt-4">
      <div class="col-12">
        <div class="alert alert-sm alert-info">
          {{$t('views.analyticByObject.dataEmpty')}}
        </div>
      </div>
    </div>

    <popup
      :closeButton="excelPopup.closeButton"
      :show="excelPopup.show"
      @closePopup="closeExcelPopup"
    >
      <div slot="header">{{ $t('views.analyticByObject.downloadExcelTitle') }}</div>
      <div slot="body">
        <div class="mt-4 alert alert-sm alert-success">
          <div>{{ $t('views.analyticByObject.downloadExcelText') }}</div>
          <div class="mt-3">
            <a :href="excelLink" target="_blank"><b>{{ excelLink }}</b></a>
          </div>
        </div>
      </div>
    </popup>


  </div>
</template>

<style scoped lang="scss">
.wrapper {
  bottom: -45px;

  .button {
    width: 100%;
    max-width: 350px;
    height: 35px;
    position: relative;

    &::before {
      content: "";
      width: 120%;
      height: 10px;
      display: block;
      position: absolute;
      background: #fff;
      top: -1px;
      left: -32px;
    }
  }

}


.analytic-by-objects--stat-card {
  background-color: #EFF2F7;
}
.analytic-by-objects--stat-card:has(.cursor-pointer:hover) {
  background-color: #ebedf2;
}

.td {
  width: 75px !important;
}

.day-statistic-row:hover,
.day-statistic-row.active {
  background: #f8f9fa;
  cursor: pointer;
}
</style>
