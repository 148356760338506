<template>
  <div class="sort-back-forth--container no-select">
    <div class="row">
      <div class="col-12">
        <div class="sort-back-forth--items">
          <div class="sort-back-forth--item title"><b>{{$t('views.analyticByObject.sortFields.sort')}}:</b></div>
          <div 
            class="sort-back-forth--item"
            :class="{'active': item.active}" 
            v-for="item in sortFields" 
            :key="item.code"
            @click="selectSort(item.code)"
          >
            <div class="name">{{item.name}}</div>
            <div class="arrows">
              <div>
              <i 
                :class="{
                  'mdi mdi-menu-swap': !item.active, 
                  'mdi mdi-menu-up': item.active && item.direction == 'ASC',
                  'mdi mdi-menu-down': item.active && item.direction == 'DESC'
                }"
              ></i></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "SortBackForth",
  props: {
    sortFields: Array, // [ {code, name, direction, active} ]
  },
  watch: {
    sortFields: function(){
      this.emitSort();
    }
  },
  computed: {
    
  },
  methods: {
    selectSort(code){
      for(let key in this.sortFields){
        if(this.sortFields[key].code === code){
          if(this.sortFields[key].active){
            if(this.sortFields[key].direction == 'ASC'){
              this.$set(this.sortFields[key], 'direction', 'DESC');
            }else if(this.sortFields[key].direction == 'DESC'){
              this.$set(this.sortFields[key], 'direction', 'ASC');
            }
          }else{
            for(let keyT in this.sortFields){
              this.$set(this.sortFields[keyT], 'active', false);
            }
            this.$set(this.sortFields[key], 'active', true);
            this.$set(this.sortFields[key], 'direction', 'ASC');
          }
        }
      }
      this.emitSort();
    },
    emitSort(){
      for(let key in this.sortFields){
        if(this.sortFields[key].active){
          this.$emit('selectSort', {code: this.sortFields[key].code, direction: this.sortFields[key].direction});
        }
      }
    }
  },
  mounted(){
    this.emitSort();
  }
};
</script>

<style scoped lang="scss">
.sort-back-forth--items{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  .active{
    color: #515FCB;
    font-weight: 600;
  }
  .title{
    color: #000;
  }
}
.sort-back-forth--item{
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  margin-right: 10px;
  cursor: pointer;
}
</style>
