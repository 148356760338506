var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-1 text-nowrap"},[_c('i',{staticClass:"fas fa-user",staticStyle:{"color":"#5664D2"}}),_vm._v(" "+_vm._s(_vm.formatNumber(_vm.stat.all_user_count))+" ")]),_c('div',{staticClass:"col-lg-1 text-nowrap"},[_c('i',{staticClass:"fas fa-shopping-cart",staticStyle:{"color":"#5664D2"}}),_vm._v(" "+_vm._s(_vm.formatNumber(_vm.stat.all_done_order_count))+" ")]),_c('div',{staticClass:"col-lg-1 text-nowrap"},[_c('i',{staticClass:"fas fa-file",staticStyle:{"color":"#5664D2"}}),_vm._v(" "+_vm._s(_vm.formatNumber(_vm.stat.all_sheet_count))+" ")])])]),_c('div',{staticClass:"col-lg-8 mt-3"},[_c('div',{staticClass:"row h-100"},[(_vm.stat.new_users.show == 1)?_c('div',{class:_vm.colWidth},[_c('div',{staticClass:"card h-100"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"media"},[_c('div',{staticClass:"media-body overflow-hidden"},[_c('p',{staticClass:"text-truncate font-size-14 mb-2"},[_vm._v(_vm._s(_vm.$t('views.summury.usersPerPeriod')))]),_c('div',{staticClass:"value-row"},[_c('div',{staticClass:"first"},[_vm._v(_vm._s(_vm.formatNumber(_vm.stat.new_users.period)))]),_c('div',{staticClass:"second"},[_vm._v(_vm._s(_vm.formatNumber(_vm.stat.new_users.prev_period)))])])]),_vm._m(0)])]),_c('div',{staticClass:"card-body border-top py-3"},[_c('div',{staticClass:"text-truncate"},[_c('span',{staticClass:"badge badge-soft-success font-size-11",class:{
                'badge-soft-success': _vm.stat.new_users.percent_diff.direction == 'up',
                'badge-soft-danger': _vm.stat.new_users.percent_diff.direction == 'down',
                'badge-soft-info': _vm.stat.new_users.percent_diff.direction == 'equally',
              }},[(_vm.stat.new_users.percent_diff.direction == 'up')?_c('i',{staticClass:"mdi mdi-menu-up"}):_vm._e(),(_vm.stat.new_users.percent_diff.direction == 'down')?_c('i',{staticClass:"mdi mdi-menu-down"}):_vm._e(),_vm._v(" "+_vm._s(_vm.stat.new_users.percent_diff.value)+"% ")]),_c('span',{staticClass:"text-muted ml-2"},[_vm._v(_vm._s(_vm.$t('views.summury.periodEarlier')))])])])])]):_vm._e(),_c('div',{class:_vm.colWidth},[_c('div',{staticClass:"card h-100"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"media"},[_c('div',{staticClass:"media-body overflow-hidden"},[_c('p',{staticClass:"text-truncate font-size-14 mb-2"},[_vm._v(_vm._s(_vm.$t('views.summury.profitPerPeriod')))]),_c('div',{staticClass:"value-row"},[_c('div',{staticClass:"first"},[_vm._v(_vm._s(_vm.formatMoney(_vm.stat.income.period))+" ₽")]),_c('div',{staticClass:"second"},[_vm._v(_vm._s(_vm.formatMoney(_vm.stat.income.prev_period))+" ₽")])])]),_vm._m(1)])]),_c('div',{staticClass:"card-body border-top py-3"},[_c('div',{staticClass:"text-truncate"},[_c('span',{staticClass:"badge badge-soft-success font-size-11",class:{
                'badge-soft-success': _vm.stat.income.percent_diff.direction == 'up',
                'badge-soft-danger': _vm.stat.income.percent_diff.direction == 'down',
                'badge-soft-info': _vm.stat.income.percent_diff.direction == 'equally',
              }},[(_vm.stat.income.percent_diff.direction == 'up')?_c('i',{staticClass:"mdi mdi-menu-up"}):_vm._e(),(_vm.stat.income.percent_diff.direction == 'down')?_c('i',{staticClass:"mdi mdi-menu-down"}):_vm._e(),_vm._v(" "+_vm._s(_vm.stat.income.percent_diff.value)+"% ")]),_c('span',{staticClass:"text-muted ml-2"},[_vm._v(_vm._s(_vm.$t('views.summury.periodEarlier')))])])])])]),(_vm.stat.income_partner.show == 1)?_c('div',{class:_vm.colWidth},[_c('div',{staticClass:"card h-100"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"media"},[_c('div',{staticClass:"media-body overflow-hidden"},[_c('p',{staticClass:"text-truncate font-size-14 mb-2"},[_vm._v(_vm._s(_vm.$t('views.summury.partnerProfitPerPeriod')))]),_c('div',{staticClass:"value-row"},[_c('div',{staticClass:"first"},[_vm._v(_vm._s(_vm.formatMoney(_vm.stat.income_partner.period))+" ₽")]),_c('div',{staticClass:"second"},[_vm._v(_vm._s(_vm.formatMoney(_vm.stat.income_partner.prev_period))+" ₽")])])]),_vm._m(2)])]),_c('div',{staticClass:"card-body border-top py-3"},[_c('div',{staticClass:"text-truncate"},[_c('span',{staticClass:"badge badge-soft-success font-size-11",class:{
                'badge-soft-success': _vm.stat.income_partner.percent_diff.direction == 'up',
                'badge-soft-danger': _vm.stat.income_partner.percent_diff.direction == 'down',
                'badge-soft-info': _vm.stat.income_partner.percent_diff.direction == 'equally',
              }},[(_vm.stat.income_partner.percent_diff.direction == 'up')?_c('i',{staticClass:"mdi mdi-menu-up"}):_vm._e(),(_vm.stat.income_partner.percent_diff.direction == 'down')?_c('i',{staticClass:"mdi mdi-menu-down"}):_vm._e(),_vm._v(" "+_vm._s(_vm.stat.income_partner.percent_diff.value)+"% ")]),_c('span',{staticClass:"text-muted ml-2"},[_vm._v(_vm._s(_vm.$t('views.summury.periodEarlier')))])])])])]):_vm._e(),(_vm.stat.income_my.show == 1)?_c('div',{class:_vm.colWidth},[_c('div',{staticClass:"card h-100"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"media"},[_c('div',{staticClass:"media-body overflow-hidden"},[_c('p',{staticClass:"text-truncate font-size-14 mb-2"},[_vm._v(_vm._s(_vm.$t('views.summury.myProfitPerPeriod')))]),_c('div',{staticClass:"value-row"},[_c('div',{staticClass:"first"},[_vm._v(_vm._s(_vm.formatMoney(_vm.stat.income_my.period))+" ₽")]),_c('div',{staticClass:"second"},[_vm._v(_vm._s(_vm.formatMoney(_vm.stat.income_my.prev_period))+" ₽")])])]),_vm._m(3)])]),_c('div',{staticClass:"card-body border-top py-3"},[_c('div',{staticClass:"text-truncate"},[_c('span',{staticClass:"badge badge-soft-success font-size-11",class:{
                'badge-soft-success': _vm.stat.income_my.percent_diff.direction == 'up',
                'badge-soft-danger': _vm.stat.income_my.percent_diff.direction == 'down',
                'badge-soft-info': _vm.stat.income_my.percent_diff.direction == 'equally',
              }},[(_vm.stat.income_my.percent_diff.direction == 'up')?_c('i',{staticClass:"mdi mdi-menu-up"}):_vm._e(),(_vm.stat.income_my.percent_diff.direction == 'down')?_c('i',{staticClass:"mdi mdi-menu-down"}):_vm._e(),_vm._v(" "+_vm._s(_vm.stat.income_my.percent_diff.value)+"% ")]),_c('span',{staticClass:"text-muted ml-2"},[_vm._v(_vm._s(_vm.$t('views.summury.periodEarlier')))])])])])]):_vm._e()])]),_c('div',{staticClass:"col-lg-4"},[_c('div',{staticClass:"card h-100"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"text-center h-100"},[_c('div',{staticClass:"row h-100"},[_c('div',{staticClass:"col-lg-3 d-flex flex-column"},[_vm._m(4),_c('p',{staticClass:"text-muted font-size-14 mb-2 mt-1"},[_vm._v(_vm._s(_vm.$t('popularWords.total')))]),_c('div',{staticClass:"m-auto text-nowrap"},[_c('h5',{staticClass:"font-size-14"},[_vm._v(_vm._s(_vm.formatNumber(_vm.stat.orders.all))+" "+_vm._s(_vm.$t('popularWords.pcs')))])]),_c('div',{staticClass:"text-truncate"},[_c('span',{staticClass:"badge badge-soft-success font-size-11",class:{
                  'badge-soft-success': _vm.stat.orders.all_percent_diff.direction == 'up',
                  'badge-soft-danger': _vm.stat.orders.all_percent_diff.direction == 'down',
                  'badge-soft-info': _vm.stat.orders.all_percent_diff.direction == 'equally',
                }},[(_vm.stat.orders.all_percent_diff.direction == 'up')?_c('i',{staticClass:"mdi mdi-menu-up"}):_vm._e(),(_vm.stat.orders.all_percent_diff.direction == 'down')?_c('i',{staticClass:"mdi mdi-menu-down"}):_vm._e(),_vm._v(" "+_vm._s(_vm.stat.orders.all_percent_diff.value)+"% ")])])]),_c('div',{staticClass:"col-lg-3 d-flex flex-column"},[_vm._m(5),_c('p',{staticClass:"text-muted font-size-14 mb-2 mt-1"},[_vm._v(_vm._s(_vm.$t('popularWords.bw')))]),_c('div',{staticClass:"m-auto text-nowrap"},[_c('h5',{staticClass:"font-size-14"},[_vm._v(_vm._s(_vm.formatNumber(_vm.stat.orders.gray))+" "+_vm._s(_vm.$t('popularWords.pcs')))])]),_c('div',{staticClass:"text-truncate"},[_c('span',{staticClass:"badge badge-soft-success font-size-11",class:{
                  'badge-soft-success': _vm.stat.orders.gray_percent_diff.direction == 'up',
                  'badge-soft-danger': _vm.stat.orders.gray_percent_diff.direction == 'down',
                  'badge-soft-info': _vm.stat.orders.gray_percent_diff.direction == 'equally',
                }},[(_vm.stat.orders.gray_percent_diff.direction == 'up')?_c('i',{staticClass:"mdi mdi-menu-up"}):_vm._e(),(_vm.stat.orders.gray_percent_diff.direction == 'down')?_c('i',{staticClass:"mdi mdi-menu-down"}):_vm._e(),_vm._v(" "+_vm._s(_vm.stat.orders.gray_percent_diff.value)+"% ")])])]),_c('div',{staticClass:"col-lg-3 d-flex flex-column"},[_vm._m(6),_c('p',{staticClass:"text-muted font-size-14 mb-2 mt-1"},[_vm._v(_vm._s(_vm.$t('popularWords.color')))]),_c('div',{staticClass:"m-auto text-nowrap"},[_c('h5',{staticClass:"font-size-14"},[_vm._v(_vm._s(_vm.formatNumber(_vm.stat.orders.color))+" "+_vm._s(_vm.$t('popularWords.pcs')))])]),_c('div',{staticClass:"text-truncate"},[_c('span',{staticClass:"badge badge-soft-success font-size-11",class:{
                  'badge-soft-success': _vm.stat.orders.color_percent_diff.direction == 'up',
                  'badge-soft-danger': _vm.stat.orders.color_percent_diff.direction == 'down',
                  'badge-soft-info': _vm.stat.orders.color_percent_diff.direction == 'equally',
                }},[(_vm.stat.orders.color_percent_diff.direction == 'up')?_c('i',{staticClass:"mdi mdi-menu-up"}):_vm._e(),(_vm.stat.orders.color_percent_diff.direction == 'down')?_c('i',{staticClass:"mdi mdi-menu-down"}):_vm._e(),_vm._v(" "+_vm._s(_vm.stat.orders.color_percent_diff.value)+"% ")])])]),_c('div',{staticClass:"col-lg-3 d-flex flex-column"},[_vm._m(7),_c('p',{staticClass:"text-muted font-size-14 mb-2 mt-1"},[_vm._v(_vm._s(_vm.$t('popularWords.avgCheck')))]),_c('div',{staticClass:"m-auto text-nowrap"},[_c('h5',{staticClass:"font-size-14"},[_vm._v(_vm._s(_vm.formatMoney(_vm.stat.orders.avg_check))+" ₽")])]),_c('div',{staticClass:"text-truncate"},[_c('span',{staticClass:"badge badge-soft-success font-size-11",class:{
                  'badge-soft-success': _vm.stat.orders.percent_diff_check.direction == 'up',
                  'badge-soft-danger': _vm.stat.orders.percent_diff_check.direction == 'down',
                  'badge-soft-info': _vm.stat.orders.percent_diff_check.direction == 'equally',
                }},[(_vm.stat.orders.percent_diff_check.direction == 'up')?_c('i',{staticClass:"mdi mdi-menu-up"}):_vm._e(),(_vm.stat.orders.percent_diff_check.direction == 'down')?_c('i',{staticClass:"mdi mdi-menu-down"}):_vm._e(),_vm._v(" "+_vm._s(_vm.stat.orders.percent_diff_check.value)+"% ")])])])])])])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-primary"},[_c('i',{staticClass:"ri-account-circle-fill font-size-24"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-primary"},[_c('i',{staticClass:"ri-hand-coin-fill font-size-24"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-primary"},[_c('i',{staticClass:"ri-shopping-bag-fill font-size-24"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text-primary"},[_c('i',{staticClass:"ri-shopping-bag-fill font-size-24"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"icon-block"},[_c('i',{staticClass:"ri-stack-line"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"icon-block"},[_c('img',{attrs:{"src":require("../../../assets/images/icons/ellipse.svg")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"icon-block"},[_c('img',{attrs:{"src":require("../../../assets/images/icons/colors.svg")}})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"icon-block"},[_c('img',{attrs:{"src":require("../../../assets/images/icons/dollar.svg")}})])}]

export { render, staticRenderFns }