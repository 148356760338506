<script>

import axios from "axios";
import Formatter from '../../../components/libraries/Formatter';
import { formatMoney, formatNumber } from "../../../helpers/utils";

const formatter = new Formatter();


/**
 * Widget Component
 */
export default {
  data() {
    return {
      dateConvert: [],
      stat: {
        date_from: "-",
        date_to: "-",
        income: { period: '-', prev_period: '-', percent_diff: { value: '-', direction: 'equally' } },
        income_partner: { show: 0, period: '-', prev_period: '-', percent_diff: { value: '-', direction: 'equally' } },
        income_my: { show: 0, period: '-', prev_period: '-', percent_diff: { value: '-', direction: 'equally' } },
        new_users: { percent_diff: { value: '-', direction: 'equally' }, period: "-", prev_period: "-" },
        orders: {
          all: '-', prev_all: '-', all_percent_diff: { value: '-', direction: 'equally' }, gray: '-', prev_gray: '-', color: '-', prev_color: '-', color_percent_diff: { value: '-', direction: 'equally' },
          avg_check: '-', gray_percent_diff: { value: '-', direction: 'equally' }, percent_diff_check: { value: '-', direction: 'equally' },

        },
        prev_date_from: "-",
        prev_date_to: "-"
      }
    };
  },
  props: {
    datePeriod: Array
  },
  watch: {
    datePeriod: function (val) {
      let result = [];
      if (val.length) {
        result[0] = formatter.jsObjectToPhp(val[0]);
        result[1] = formatter.jsObjectToPhp(val[1]);
      }
      this.dateConvert = result;
    },
    dateConvert: function (val) {
      this.getStat(val)
    }
  },
  computed: {
    colWidth(){
      let blockCnt = 1; // Блок общим доходом за период показываем всегда

      if(+this.stat.new_users.show === 1){
        blockCnt++;
      }
      if(+this.stat.income_partner.show === 1){
        blockCnt++;
      }
      if(+this.stat.income_my.show === 1){
        blockCnt++;
      }
      return 'col-lg-' + (12 / blockCnt);
    }
  },
  methods: {
    formatNumber,
    formatMoney,
    getStat(dateArr) {
      let formData = new FormData();
      formData.append("date_from", dateArr[0]);
      formData.append("date_to", dateArr[1]);
      axios
        .post(`/v1/dashboard/summury`, formData)
        .then(resp => {
          this.stat = resp.data;
        })
        .catch(err => {
          this.$store.dispatch("addNotification", {
            text: err.response.data.message,
            time: 6,
            color: "danger"
          });
        });
    },
  },
  created() {
    if (this.datePeriod.length) {
      let result = [];
      result[0] = formatter.jsObjectToPhp(this.datePeriod[0]);
      result[1] = formatter.jsObjectToPhp(this.datePeriod[1]);
      this.dateConvert = result;
    }
  }
};
</script>

<template>
  <div class="row">
    <div class="col-lg-12">
      <div class="row">
        <div class="col-lg-1 text-nowrap">
          <i style="color: #5664D2;" class="fas fa-user"></i>
          {{ formatNumber(stat.all_user_count) }}
        </div>
        <div class="col-lg-1 text-nowrap">
          <i style="color: #5664D2;" class="fas fa-shopping-cart"></i>
          {{ formatNumber(stat.all_done_order_count) }}
        </div>
        <div class="col-lg-1 text-nowrap">
          <i style="color: #5664D2;" class="fas fa-file"></i>
          {{ formatNumber(stat.all_sheet_count) }}
        </div>
      </div>
    </div>
    <div class="col-lg-8 mt-3">
      <div class="row h-100">

        <div v-if="stat.new_users.show == 1" :class="colWidth">
          <div class="card h-100">
            <div class="card-body">
              <div class="media">
                <div class="media-body overflow-hidden">
                  <p class="text-truncate font-size-14 mb-2">{{$t('views.summury.usersPerPeriod')}}</p>
                  <div class="value-row">
                    <div class="first">{{ formatNumber(stat.new_users.period) }}</div>
                    <div class="second">{{ formatNumber(stat.new_users.prev_period) }}</div>
                  </div>
                </div>
                <div class="text-primary">
                  <i class="ri-account-circle-fill font-size-24"></i>
                </div>
              </div>
            </div>

            <div class="card-body border-top py-3">
              <div class="text-truncate">
                <span class="badge badge-soft-success font-size-11" :class="{
                  'badge-soft-success': stat.new_users.percent_diff.direction == 'up',
                  'badge-soft-danger': stat.new_users.percent_diff.direction == 'down',
                  'badge-soft-info': stat.new_users.percent_diff.direction == 'equally',
                }">
                  <i v-if="stat.new_users.percent_diff.direction == 'up'" class="mdi mdi-menu-up"></i>
                  <i v-if="stat.new_users.percent_diff.direction == 'down'" class="mdi mdi-menu-down"></i>
                  {{ stat.new_users.percent_diff.value }}%
                </span>
                <span class="text-muted ml-2">{{$t('views.summury.periodEarlier')}}</span>
              </div>
            </div>
          </div>
        </div>

        <div :class="colWidth">
          <div class="card h-100">
            <div class="card-body">
              <div class="media">
                <div class="media-body overflow-hidden">
                  <p class="text-truncate font-size-14 mb-2">{{$t('views.summury.profitPerPeriod')}}</p>
                  <div class="value-row">
                    <div class="first">{{ formatMoney(stat.income.period) }} &#8381;</div>
                    <div class="second">{{ formatMoney(stat.income.prev_period) }} &#8381;</div>
                  </div>
                </div>
                <div class="text-primary">
                  <i class="ri-hand-coin-fill font-size-24"></i>
                </div>
              </div>
            </div>

            <div class="card-body border-top py-3">
              <div class="text-truncate">
                <span class="badge badge-soft-success font-size-11" :class="{
                  'badge-soft-success': stat.income.percent_diff.direction == 'up',
                  'badge-soft-danger': stat.income.percent_diff.direction == 'down',
                  'badge-soft-info': stat.income.percent_diff.direction == 'equally',
                }">
                  <i v-if="stat.income.percent_diff.direction == 'up'" class="mdi mdi-menu-up"></i>
                  <i v-if="stat.income.percent_diff.direction == 'down'" class="mdi mdi-menu-down"></i>
                  {{ stat.income.percent_diff.value }}%
                </span>
                <span class="text-muted ml-2">{{$t('views.summury.periodEarlier')}}</span>
              </div>
            </div>
          </div>
        </div>

        <div v-if="stat.income_partner.show == 1" :class="colWidth">
          <div class="card h-100">
            <div class="card-body">
              <div class="media">
                <div class="media-body overflow-hidden">
                  <p class="text-truncate font-size-14 mb-2">{{$t('views.summury.partnerProfitPerPeriod')}}</p>
                  <div class="value-row">
                    <div class="first">{{ formatMoney(stat.income_partner.period) }} &#8381;</div>
                    <div class="second">{{ formatMoney(stat.income_partner.prev_period) }} &#8381;</div>
                  </div>
                </div>
                <div class="text-primary">
                  <i class="ri-shopping-bag-fill font-size-24"></i>
                </div>
              </div>
            </div>

            <div class="card-body border-top py-3">
              <div class="text-truncate">
                <span class="badge badge-soft-success font-size-11" :class="{
                  'badge-soft-success': stat.income_partner.percent_diff.direction == 'up',
                  'badge-soft-danger': stat.income_partner.percent_diff.direction == 'down',
                  'badge-soft-info': stat.income_partner.percent_diff.direction == 'equally',
                }">
                  <i v-if="stat.income_partner.percent_diff.direction == 'up'" class="mdi mdi-menu-up"></i>
                  <i v-if="stat.income_partner.percent_diff.direction == 'down'" class="mdi mdi-menu-down"></i>
                  {{ stat.income_partner.percent_diff.value }}%
                </span>
                <span class="text-muted ml-2">{{$t('views.summury.periodEarlier')}}</span>
              </div>
            </div>
          </div>
        </div>


        <div v-if="stat.income_my.show == 1" :class="colWidth">
          <div class="card h-100">
            <div class="card-body">
              <div class="media">
                <div class="media-body overflow-hidden">
                  <p class="text-truncate font-size-14 mb-2">{{$t('views.summury.myProfitPerPeriod')}}</p>
                  <div class="value-row">
                    <div class="first">{{ formatMoney(stat.income_my.period) }} &#8381;</div>
                    <div class="second">{{ formatMoney(stat.income_my.prev_period) }} &#8381;</div>
                  </div>
                </div>
                <div class="text-primary">
                  <i class="ri-shopping-bag-fill font-size-24"></i>
                </div>
              </div>
            </div>

            <div class="card-body border-top py-3">
              <div class="text-truncate">
                <span class="badge badge-soft-success font-size-11" :class="{
                  'badge-soft-success': stat.income_my.percent_diff.direction == 'up',
                  'badge-soft-danger': stat.income_my.percent_diff.direction == 'down',
                  'badge-soft-info': stat.income_my.percent_diff.direction == 'equally',
                }">
                  <i v-if="stat.income_my.percent_diff.direction == 'up'" class="mdi mdi-menu-up"></i>
                  <i v-if="stat.income_my.percent_diff.direction == 'down'" class="mdi mdi-menu-down"></i>
                  {{ stat.income_my.percent_diff.value }}%
                </span>
                <span class="text-muted ml-2">{{$t('views.summury.periodEarlier')}}</span>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="col-lg-4">
      <div class="card h-100">
        <div class="card-body">
          <div class="text-center h-100">
            <div class="row h-100">
              <div class="col-lg-3 d-flex flex-column">
                <div class="icon-block">
                  <i class="ri-stack-line"></i>
                </div>
                <p class="text-muted font-size-14 mb-2 mt-1">{{$t('popularWords.total')}}</p>
                <div class="m-auto text-nowrap">
                  <h5 class="font-size-14">{{ formatNumber(stat.orders.all) }} {{$t('popularWords.pcs')}}</h5>
                </div>
                <div class="text-truncate">
                  <span class="badge badge-soft-success font-size-11" :class="{
                    'badge-soft-success': stat.orders.all_percent_diff.direction == 'up',
                    'badge-soft-danger': stat.orders.all_percent_diff.direction == 'down',
                    'badge-soft-info': stat.orders.all_percent_diff.direction == 'equally',
                  }">
                    <i v-if="stat.orders.all_percent_diff.direction == 'up'" class="mdi mdi-menu-up"></i>
                    <i v-if="stat.orders.all_percent_diff.direction == 'down'" class="mdi mdi-menu-down"></i>
                    {{ stat.orders.all_percent_diff.value }}%
                  </span>
                </div>
              </div>
              <div class="col-lg-3 d-flex flex-column">
                <div class="icon-block">
                  <img src="../../../assets/images/icons/ellipse.svg">
                </div>
                <p class="text-muted font-size-14 mb-2 mt-1">{{$t('popularWords.bw')}}</p>
                <div class="m-auto text-nowrap">
                  <h5 class="font-size-14">{{ formatNumber(stat.orders.gray) }} {{$t('popularWords.pcs')}}</h5>
                </div>
                <div class="text-truncate">
                  <span class="badge badge-soft-success font-size-11" :class="{
                    'badge-soft-success': stat.orders.gray_percent_diff.direction == 'up',
                    'badge-soft-danger': stat.orders.gray_percent_diff.direction == 'down',
                    'badge-soft-info': stat.orders.gray_percent_diff.direction == 'equally',
                  }">
                    <i v-if="stat.orders.gray_percent_diff.direction == 'up'" class="mdi mdi-menu-up"></i>
                    <i v-if="stat.orders.gray_percent_diff.direction == 'down'" class="mdi mdi-menu-down"></i>
                    {{ stat.orders.gray_percent_diff.value }}%
                  </span>
                </div>
              </div>
              <div class="col-lg-3 d-flex flex-column">
                <div class="icon-block">
                  <img src="../../../assets/images/icons/colors.svg">
                </div>
                <p class="text-muted font-size-14 mb-2 mt-1">{{$t('popularWords.color')}}</p>
                <div class="m-auto text-nowrap">
                  <h5 class="font-size-14">{{ formatNumber(stat.orders.color) }} {{$t('popularWords.pcs')}}</h5>
                </div>
                <div class="text-truncate">
                  <span class="badge badge-soft-success font-size-11" :class="{
                    'badge-soft-success': stat.orders.color_percent_diff.direction == 'up',
                    'badge-soft-danger': stat.orders.color_percent_diff.direction == 'down',
                    'badge-soft-info': stat.orders.color_percent_diff.direction == 'equally',
                  }">
                    <i v-if="stat.orders.color_percent_diff.direction == 'up'" class="mdi mdi-menu-up"></i>
                    <i v-if="stat.orders.color_percent_diff.direction == 'down'" class="mdi mdi-menu-down"></i>
                    {{ stat.orders.color_percent_diff.value }}%
                  </span>
                </div>
              </div>
              <div class="col-lg-3 d-flex flex-column">
                <div class="icon-block">
                  <img src="../../../assets/images/icons/dollar.svg">
                </div>
                <p class="text-muted font-size-14 mb-2 mt-1">{{$t('popularWords.avgCheck')}}</p>
                <div class="m-auto text-nowrap">
                  <h5 class="font-size-14">{{ formatMoney(stat.orders.avg_check) }} &#8381;</h5>
                </div>
                <div class="text-truncate">
                  <span class="badge badge-soft-success font-size-11" :class="{
                    'badge-soft-success': stat.orders.percent_diff_check.direction == 'up',
                    'badge-soft-danger': stat.orders.percent_diff_check.direction == 'down',
                    'badge-soft-info': stat.orders.percent_diff_check.direction == 'equally',
                  }">
                    <i v-if="stat.orders.percent_diff_check.direction == 'up'" class="mdi mdi-menu-up"></i>
                    <i v-if="stat.orders.percent_diff_check.direction == 'down'" class="mdi mdi-menu-down"></i>
                    {{ stat.orders.percent_diff_check.value }}%
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
  <!-- end row -->
</template>

<style scoped lang="scss">
.value-row {
  div {
    display: inline-block;
  }

  .first {
    display: block;
    font-size: 20px;
    font-weight: 700;
    color: #000;
  }

  .second {
    font-size: 16px;
    color: gray;
  }
}

.icon-block {
  height: 25px;
}
</style>
