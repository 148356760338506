import Vue from "vue";

export function formatNumber(value, decimals = 0) {
    if (typeof value == 'string') {
        value = Number(value); // Если передана строка - пробуем её распарсить в число
    }
    if (!Number.isFinite(value)) {
        return undefined; // Некорректное число
    }

    let v = value.toFixed(decimals);
    let offset = decimals > 0
      ? 4 + decimals // сотни + точка + кол-во чисел после запятой
      : 3; // сотни
    for (let i = v.length - offset; i > 0; i -= 3) {
        v = v.slice(0, i) + ' ' + v.slice(i)
    }
    return v;
}

export function formatMoney(value) {
    return formatNumber(value, 2);
}

export function showConfirmDialog(message, { ok, cancel } = {}) {
    return Vue.prototype.$showConfirmDialog(message, ok, cancel);
}

export const ORDER_STATUSES = [
    'new',                  // Новый
    'paid',                 // Оплачен и ожидает печати
    'processing',           // В работе (идет печать)
    'in_delivery',          // Передан в доставку
    'ready_for_pickup',     // Готов к выдаче (самовывоз)
    'done',                 // Завершен успешно
    'failed',               // Завершен с ошибкой
    'refunded',             // Средства за заказ возвращены
    'refund_failed'         // Ошибка взврата
];

export function getStatusName(statusKey) {
    if (statusKey === 'new') { return 'Новый'; }
    if (statusKey === 'paid') { return 'Ожидает печати'; }
    if (statusKey === 'processing') { return 'Обрабатывается'; }
    if (statusKey === 'in_delivery') { return 'Передан курьеру'; }
    if (statusKey === 'ready_for_pickup') { return 'Готов к выдаче'; }
    if (statusKey === 'done') { return 'Выполнен'; }
    if (statusKey === 'failed') { return 'Завершен с ошибкой'; }
    if (statusKey === 'refunded') { return 'Возврат средств'; }
    if (statusKey === 'refund_failed') { return 'Ошибка возврата'; }
    return statusKey;
}
